<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :items="newsList"
          :total-items="totalItems"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          :headers="headers"
          :search="search"
          title="Berita"
          subtitle="Halaman untuk menambahkan Berita"
          add-btn="Tambah Data"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @show-image="showImage"
          @delete-item="confirmDestroy"
          @page="page"
          @change-page="paginationHandler"
          @live-search="searchHandler"
          @check-description="checkDescription"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid || publish_time === null || publish_date === null || news.thumbnail === null "
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Berita': 'Edit Berita'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <v-row>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="12"
            cols="12"
            class="py-0"
          >
            <v-menu
              v-model="date_menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="publish_date"
                  label="Tanggal Terbit"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click="autofillDate"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="publish_date"
                :first-day-of-week="0"
                locale="id-id"
                color="primary"
                @input="date_menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="12"
            cols="12"
            class="py-0"
          >
            <v-menu
              ref="menu"
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="publish_time"
                  label="Jam Terbit"
                  :prepend-icon="icons.mdiClockTimeFourOutline"
                  readonly
                  outlined
                  v-bind="attrs"
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu2"
                v-model="publish_time"
                color="primary"
                full-width
                format="24hr"
                @click:minute="$refs.menu.save(time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="news.title"
                label="Judul"
                outlined
                dense
                :rules="[validation.required]"
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="mb-4"
          >
            <div>
              <CKEditor
                :value="initialContent"
                label="Content"
                outlined
                dense
                @input="handleCKeditor"
              ></CKEditor>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="pt-0 pb-3"
          >
            <FileInput
              :value="initialFile"
              label="Thumbnail"
              outlined
              :prependicon="icons.mdiImageMultipleOutline"
              dense
              @input="getFile"
            ></FileInput>
          </v-col>
        </v-row>
      </template>
    </ModalDialog>
    <ModalDialog
      :visible="modalDescription"
      :width="widthModal"
      :hidden-actions="false"
      @close-modal="modalDescription = false"
    >
      <template
        v-slot:header
      >
        Deskripsi Berita
      </template>
      <template v-slot:body>
        <p v-html="detail.content"></p>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          outlined
          large
          @click="modalDescription = false"
        >
          Tutup
        </v-btn>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Berita"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>

    <ImageOverlay
      :visible="imageOverlay"
      :width="width"
      @close="imageOverlay = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="closeImage"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="news.thumbnail"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
  </div>
</template>

<script>
import { integerValidator, required } from '@core/utils/validation'
import { mdiClose, mdiImageMultipleOutline } from '@mdi/js'
import CKEditor from '@/views/components/CkEditor.vue'
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import FileInput from '@/views/components/FileInput.vue'
import ImageOverlay from '@/views/components/ImageOverlay.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'

export default {
  name: 'News',
  components: {
    DataTablePagination,
    ModalDialog,
    ConfirmDialog,
    ImageOverlay,
    FileInput,
    SkeletonLoaderTable,
    MainCard,
    CKEditor,
  },

  data() {
    return {
      widthModal: 810,
      skeleton: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 5,
        types: {},
      },
      search: '',
      page: 1,
      totalItems: 0,
      totalPages: 0,
      dialog: '',
      width: 700,
      modalDialog: false,
      confirmDialog: false,
      imageOverlay: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      overlay: false,
      zIndex: 10,
      icons: {
        mdiClose,
        mdiImageMultipleOutline,
      },
      validation: {
        required,
        integerValidator,
      },
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Tanggal Terbit', value: 'publish_at' },
        { text: 'Judul', value: 'title' },
        { text: 'Isi Berita', value: 'news_content' },
        { text: 'Thumbnail', value: 'thumbnail' },
        { text: 'Author', value: 'user.name' },
        { text: 'Actions', value: 'actions' },
      ],
      newsList: [],
      news: {
        uuid: '',
        title: '',
        content: null,
        thumbnail: null,
        publish_at: '',
        is_publish: 1,
      },

      publish_date: null,
      publish_time: null,
      date_menu: false,
      time: null,
      menu2: false,
      detail: { content: '' },
      modalDescription: false,
      tempContent: '',
      tempImage: [],
      filterQuery: {
        news: '',
      },
      formValid: false,
    }
  },

  computed: {
    initialFile: {
      get() {
        if (typeof this.news.thumbnail === 'string') {
          return new File([this.news.thumbnail], this.news.thumbnail, { type: 'text/plain' })
        }

        return this.news.thumbnail
      },
      set() {
        return this.news.thumbnail
      },
    },
    initialContent: {
      get() {
        if (this.news.content === null) {
          return ''
        }

        return this.news.content
      },
      set() {
        return this.news.content
      },
    },
  },

  watch: {
    news: {
      handler() {
        const valid = []
        const requiredField = ['title', 'content']
        Object.entries(this.news).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.listNews(this.filterQuery)
      },
    },
  },

  async mounted() {
    this.isLoadingTable = true
    await this.listNews()
    this.isLoadingTable = false
  },

  methods: {
    cancelAction() {
      this.modalDialog = false
      const image = this.tempContent.split('"')
      image.forEach(async item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const fileName = {
            file: img,
          }
          await this.deleteStorage(fileName)
        }
      })
      this.tempContent = ''
    },
    async deleteStorage(fileName) {
      await this.$services.ApiServices.deleteStorage('news', fileName).then(({ data }) => true)
    },
    async checkDescription(uuid) {
      await this.$services.ApiServices.get('news', uuid).then(({ data }) => {
        this.detail = data.data
      })
      this.modalDescription = true
    },
    handleCKeditor(event) {
      this.news.content = event
      if (this.tempContent.length < event.length) {
        this.tempContent = event
      }
    },

    autofillDate() {
      this.publish_date = new Date().toISOString().substr(0, 10)
    },

    async listNews(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('news', {
        ...params,
        is_admin: '1',
        page: this.page,
      }).then(
        ({ data }) => {
          this.newsList = data.data.map((newsList, index) => ({
            ...newsList,
            index: index + 1,
          }))
          this.totalPages = data.meta.last_page
          this.totalItems = data.meta.total
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },

    resetForm() {
      this.news.uuid = ''
      this.news.title = ''
      this.news.content = ''
      this.news.publish_at = ''
      this.news.thumbnail = null
      this.publish_date = ''
      this.publish_time = ''
    },

    showFormAdd() {
      this.tempContent = ''
      this.tempImage = []
      this.dialog = 'add'
      this.resetForm()
      this.modalDialog = true
    },

    async add() {
      this.isLoadingButton = true
      this.news.publish_at = `${this.publish_date} ${this.publish_time}`
      if (JSON.parse(localStorage.getItem('user')).user.role === 'admin') {
        this.school_uuid = ''
      }
      this.news.school_uuid = this.school_uuid
      this.formData = new FormData()
      Object.entries(this.news).forEach(([key, item]) => {
        this.formData.append(key, item)
      })
      const tempImage = this.tempContent.split('"')
      tempImage.forEach(async item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          this.tempImage.push(img)
        }
      })
      const image = this.news.content.split('"')
      image.forEach(item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const carIndex = this.tempImage.indexOf(img)
          this.tempImage.splice(carIndex, 1)

          this.tempImage.forEach(async item => {
            await this.deleteStorage({ file: item })
          })
        }
      })
      await this.$services.ApiServices.add('news', this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listNews(this.filterQuery)
    },

    async showFormEdit(uuid) {
      this.tempContent = ''
      this.tempImage = []
      this.dialog = 'edit'
      await this.$services.ApiServices.get('news', uuid).then(
        ({ data }) => {
          this.news.is_publish = 1
          this.news.uuid = data.data.uuid
          this.news.school_uuid = data.data.school?.uuid ?? ''
          this.news.title = data.data.title
          this.news.content = data.data.content
          const image = data.data.content.split('"')
          image.forEach(async item => {
            const img = item.split('/').pop()
            if (img.length === 44) {
              this.tempImage.push(img)
            }
          })
          this.news.publish_at = data.data.publish_at
          this.news.thumbnail = data.data.thumbnail.split('/').pop()
          // eslint-disable-next-line prefer-destructuring
          this.publish_date = data.data.publish_at.split(' ')[0]
          // eslint-disable-next-line prefer-destructuring
          this.publish_time = data.data.publish_at.split(' ')[1].slice(0, 5)
        },
        err => console.error(err),
      )
      this.modalDialog = true
    },

    async update() {
      this.isLoadingButton = true

      this.news.publish_at = `${this.publish_date} ${this.publish_time}`

      this.formData = new FormData()
      Object.entries(this.news).forEach(([key, item]) => {
        this.formData.append(key, item)
      })

      const image = this.news.content.split('"')
      image.forEach(item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const carIndex = this.tempImage.indexOf(img)
          this.tempImage.splice(carIndex, 1)

          this.tempImage.forEach(async item => {
            await this.deleteStorage({ file: item })
          })
        }
      })

      const oldThumbnail = this.news.thumbnail
      if (typeof oldThumbnail && typeof this.formData.get('thumbnail') === 'string') {
        this.formData.delete('thumbnail')
      }
      if (this.formData.get('school_uuid') === '' || this.formData.get('school_uuid') === null) {
        this.formData.delete('school_uuid')
      }
      await this.$services.ApiServices.update('news', this.formData, this.news.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listNews(this.filterQuery)
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },

    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.news.uuid = uuid
    },

    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('news', this.news.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listNews(this.filterQuery)
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    searchHandler(news) {
      this.filterQuery.news = news
      this.page = 1
      this.listNews(this.filterQuery)
    },

    async showImage(uuid) {
      await this.$services.ApiServices.get('news', uuid).then(
        ({ data }) => {
          this.news = data.data
          this.news.thumbnail = data.data.thumbnail
        },
        err => console.error(err),
      )
      this.imageOverlay = true
    },

    closeImage() {
      this.overlay = false
      this.imageOverlay = false
    },

    getFile(data) {
      this.news.thumbnail = data
    },
  },
}
</script>
